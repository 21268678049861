<template>
  <base-section
    id="features"
    style="background: linear-gradient(170deg,#d59e0e,#0a1a72);"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="3"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-factory',
          title: 'Find your chemical solution',
          text: '',
        },
        {
          icon: 'mdi-earth',
          title: 'Connected globally ',
          text: '',
        },
        {
          icon: 'mdi-airplane',
          title: 'international distribution',
          text: '',
        },
        {
          icon: 'mdi-certificate',
          title: 'industry standard approved and recognized',
          text: '',
        },
      ],
    }),
  }
</script>
